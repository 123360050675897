export default {
  title: "Kunden",
  name: "Name",
  code: "Code",
  domain: "Domain",
  module_code: "Modulcode",
  clientModules: "Client-Module",
  moduleUpdated: "Modul erfolgreich aktualisiert",
  manageModules: "Module verwalten",
  createClient: "Client erstellen",
  more: "code",
  enable: "Status",
  zapierEmail: "Zapier EMail",
  modules: "Module",
  and: "and",
  moreClient: "more",
  integrations: "Integrationen",
  logo: "Logo",
};
