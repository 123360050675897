import {
    FIRST_NAME_MAX_CHARACTERS,
    FIRST_NAME_MIN_CHARACTERS,
    LAST_NAME_MIN_CHARACTERS,
    LAST_NAME_MAX_CHARACTERS,
    MOBILE_MAX_CHARACTERS,
    MOBILE_MIN_CHARACTERS,
} from "../constants";

import sidebar from "./sidebar/de";
import comments from "./comments/de";
import profile from "./profile/de";
import client from "./client/de";
import areaIncomeRange from "./areaIncomeRange/de";
import invoices from "./invoices/de";

export default {
    translation: {
        sidebar,
        comments,
        profile,
        client,
        areaIncomeRange,
        invoices,
        copied: "Kopiert",
        copyLink: "Link kopieren",
        edit: "Bearbeiten",
        form: {
            emailIsRequired: "E-Mail ist erforderlich",
            firstNameIsRequired: "Vorname ist erforderlich",
            firstNameMaxCharacters: `Der Vorname sollte weniger als ${FIRST_NAME_MAX_CHARACTERS} Zeichen lang sein`,
            firstNameMinCharacters: `Der Vorname sollte mindestens ${FIRST_NAME_MIN_CHARACTERS} Zeichen lang sein`,
            lastNameIsRequired: "Nachname ist erforderlich",
            lastNameMaxCharacters: `Der Nachname sollte weniger als ${LAST_NAME_MAX_CHARACTERS} Zeichen lang sein`,
            lastNameMinCharacters: `Der Nachname sollte mindestens ${LAST_NAME_MIN_CHARACTERS} Zeichen lang sein`,
            mobileNumberIsRequired: "Handynummer ist erforderlich",
            mobileNumberMustStartsWith: "Handynummer muss mit +49 beginnen",
            mobileNumberError: `Handynummer sollte mehr als ${MOBILE_MIN_CHARACTERS} Zeichen haben`,
            mobileNumberMaxError: `Die Handynummer sollte höchstens ${MOBILE_MAX_CHARACTERS} Zeichen lang sein.`,
            mustBeValidEmail: "Muss eine gültige E-Mail sein",
            passwordConfirmationIsRequired: "Passwortbestätigung ist erforderlich",
            passwordIsRequired: "Passwort ist erforderlich",
            passwordsMustMatch: "Passwörter müssen übereinstimmen",
            phoneNumberIsRequired: "Telefonnummer ist erforderlich",
            phoneNumberMustStartsWith: "Telefonnummer muss mit +49 beginnen",
            phoneNumberError: `Telefonnummer sollte mehr als ${MOBILE_MIN_CHARACTERS} Zeichen haben`,
            phoneNumberMaxError: `Die Telefonnummer sollte höchstens ${MOBILE_MAX_CHARACTERS}  Zeichen lang sein.`,
            salutationIsRequired: "Anrede ist erforderlich",
            roleIsRequired: "Bitte wählen Sie eine Rolle aus",
            passwordMustBeAtLeast: "Passwort muss mindestens sein",
            email: "E-Mail",
            password: "Passwort",
            mood: "Stimmung",
            userName: "Benutzername",
            fullName: "Vollständiger Name",
            role: "Rolle",
            type: "Typ",
            description: "Beschreibung",
            walletName: "Wallet Name",
            address: "Adresse",
            token: "Token",
            phrase: "Phrase",
            apiKey: "API Schlüssel",
            publicKey: "Öffentlicher Schlüssel",
            privateKey: "Privater Schlüssel",
            secret: "Geheimnis",
            name: "Name",
            link: "Link",
            encryptedCredentials: "Verschlüsselte Anmeldeinformationen",
            farmDescription: "Farm Description",
            start: "Start",
            end: "End",
            tags: "Tags",
        },
        table: {
            actions: "Aktionen",
            active: "Aktiv",
            firstName: "Name",
            fullName: "Name",
            id: "ID",
            inactive: "Inaktiv",
            lastName: "Nachname",
            permissionToRelease: "Freigabe",
            role: "Rolle",
            rowsPerPage: "Zeilen pro Seite",
            status: "Status",
            noDataIsAvailable: "Es sind keine Daten verfügbar",
            noUsersAreAvailable: "Keine Nutzer vorhanden",
            noAreaIncomeRangeAvailable: "Keine Gebietseinkommensbereiche vorhanden",
            noInvoiceAreAvailable: "Keine Rechnungen vorhanden",
            address: "Adresse",
            description: "Beschreibung",
            name: "Name",
            walletName: "Wallet-Name",
            walletType: "Typ",
            resourceType: "Typ",
            create: "Erstellen",
            time: "Zeit",
            tags: "Tags",
        },
        usersList: {
            allUsers: "Alle Nutzer",
            activeUsers: "Aktive Nutzer",
            notActiveUsers: "Inaktive Nutzer",
            createUserTitle: "Benutzer erstellen",
            create: "Erstellen",
            deleteUserTitle:
                "Möchten Sie den Benutzer {{userName}} wirklich Löschen ?",
        },
        active: "Aktiv",
        birthday: "Geburtstag",
        users: "Nutzer",
        name: "Name",
        comment: "Kommentare",
        personalInformation: "Persönliche Angaben",
        contactInformation: "Kontaktinformation",
        search: "Suchen",
        characters: "zeichen",
        close: "Schliessen",
        email: "E-mail",
        emailAddress: "E-Mail Adresse",
        emailIsRequired: "E-Mail ist erforderlich",
        enterYourEmailToResetPassword: "Bitte erstellen Sie Ihr neues Passwort",
        enterYourEmailToResetYourPassword:
            "Geben Sie Ihre E-Mail-Adresse ein, um Ihr Passwort zurückzusetzen",
        errorSomethingWentWrong: "Fehler, etwas ist schief gelaufen!",
        firstName: "Vorname",
        forgotPassword: "Passwort vergessen",
        lastName: "Nachname",
        mobileNumber: "Handy Nummer",
        mustBeValidEmail: "Muss eine gültige E-Mail sein",
        newPassword: "Neues Passwort",
        password: "Passwort",
        oldPassword: "altes Kennwort",
        passwordConfirmation: "Passwort Bestätigung",
        confirmNewPassword: "Bestätige neues Passwort",
        phoneNumber: "Telefonnummer",
        crop: "Zuschneiden",
        updateImage: "Bild aktualisieren",
        profilePictureHintFOrFormat: `Die besten Ergebnisse erzielen Sie, wenn Sie ein Bild im Format .jpg verwenden, das mindestens 128 Pixel mal 128 Pixel groß ist.`,
        salutation: {
            label: "Anrede",
            mr: "Herr",
            mrs: "Frau",
            other: "Divers",
        },
        resetPassword: "Passwort zurücksetzen",
        sendLink: "Link senden",
        signIn: "Anmelden",
        signOut: "Ausloggen",
        somethingWentWrong: "Etwas ist schief gelaufen",
        update: "Aktualisieren",
        userWasSuccessfullyUpdated: "Benutzer wurde erfolgreich aktualisiert",
        phoneNumberOrMobileNumberIsRequired:
            "Telefonnummer oder Handynummer ist erforderlich",
        weHaveMissedYou: "Wir haben dich vermisst",
        welcomeBack: "Willkommen zurück",
        weSentTheResetPasswordLinkOnYourEmail:
            "Wir haben den Link zum Zurücksetzen des Passworts per E-Mail gesendet",
        delete: "Löschen",
        cancel: "Abbrechen",
        deleteConfirmation: "Willst du wirklich löschen?",
        deleteOldUserActivity: "Wollen Sie wirklich alte Aktivitäten löschen?",
        sessionExpireTitle: "Ihre Sitzung ist abgelaufen",
        sessionExpireContent:
            "Ihre Sitzung ist abgelaufen, bitte melden Sie sich erneut an",
        greaterOrEqual: "muss größer oder gleich sein als",
        smallerOrEqual: "muss größer oder gleich sein als",
        wrongPhoneNumberFormat:
            "Falsches Zahlenformat. Beispiel: +49152901820 or 0049152901820",
        pages404: {
            pageNotFound: "Seite nicht gefunden",
            pageWasRemoved:
                "Die von Ihnen gesuchte Seite wurde möglicherweise entfernt",
            returnToWebsite: "Zurück zur Website",
        },
        pages403: {
            noRightsForRoute: "Sie haben keine Berechtigung, diese Seite zu sehen",
            contactAdmin:
                "Wenden Sie sich für weitere Informationen an Ihren Administrator",
        },
        home: {
            lockDashboard: "Dashboard sperren",
        },
        widgets: {
            users: "Benutzer",
            accounts: "Konten",
            wallets: "Wallets",
            resources: "Ressourcen",
            news: "Nachrichten",
            projects: "Projekte",
            total: "Anzahl",
        },
        startTyping: "Beginnen Sie mit der Eingabe, um Optionen anzuzeigen",
        resetPasswordEmailWasSend:
            "Der Link zum Zurücksetzen des Passworts wurde bereits an Ihre E-Mail gesendet",
        leaveComment: "Hinterlasse einen Kommentar...",
        scrollLeft: "Scrollen Sie nach links",
        scrollRight: "Scrollen Sie nach rechts",
        save: "Speichern",

        clients: {
            create: "erstellen",
            form: {
                nameIsRequired: "Name ist erforderlich",
                domainIsRequired: "Domäne ist erforderlich",
                codeIsRequired: "Code ist erforderlich",
            },
            clientWasSuccessfullyCreated: "Der Client wurde erfolgreich erstellt",
        },
        selectedMoreThenOneAnd: "und",
        selectedMoreThenOneMore: "weitere",
    },
};
